<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import EmptyList from '@/components/widgets/empty_list'
import AudienceFields from "@/components/widgets/audienceFields.vue";
import ExpressionBuilder from "@/components/widgets/queryBuilder";
import helpers from '../../../helpers'
import { required } from "vuelidate/lib/validators";

import {
  audienceMethods,
  propertyMethods,
  projectMethods,
  customerMethods,
  interactionMethods,
  campaignMethods,
  userMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Audience Explorer",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Multiselect,
    EmptyList,
    AudienceFields,
    ExpressionBuilder
  },
  data() {
    return {
      title: this.$t('kpi.title'),
      items: [],

      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      frequencies: [
        { name: this.$t("audiences.audience_conditions_frequency_30"), id: 30 },
        { name: this.$t("audiences.audience_conditions_frequency_60"), id: 60 },
        { name: this.$t("audiences.audience_conditions_frequency_90"), id: 90 },
      ],
      frequency: { name: this.$t("audiences.audience_conditions_frequency_30"), id: 30 },
      pageLoaded: false,
      empty_config: {
        "title": this.$t("audiences.audiences_conditions_empty"),
        "subtitle": this.$t("audiences.audiences_conditions_empty_subtitle"),
        "buttonText": this.$t("audiences.audience_conditions_add_condition"),
        icon: "fa-cubes",
        academy: "https://academy.gopersonal.ai/guia-de-usuario/segmentacion/audiencias#condiciones"
      },
      query: {
        type: "or",
        children: []
      },
      properties_products: [],
      properties_crm: [],
      affinity_Types: [],
      categories: [],
      rfm_segments: [],
      custom_interactions: [],
      showAudienceFields: false,
      campaigns: [],
      hosts: [],
      contactLists: [],
      chartOptions: {
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
              },
              size: "65%",  // Tamaño más pequeño del donut
              startAngle: 0, // Ángulo de inicio
              endAngle: 360, // Ángulo de fin
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 100,
              },
            },
          },
        ],
        colors: ["#5e40bf", "#f8f9fa"],
      },
      series: [0, 100],
      successCount: 0,
      totalCount: 0,
      percentage: 0,
      totalRevenue: 0,
      totalAOV: 0,
      purchases: 0,
      lastResult: null,
      withError: false,
      showSaveAudienceModal: false,
      audience: {},
      submitted: false
    };
  },
  validations: {
    audience: {
      name: { required },
      description: { required },
    }
  },
  mounted() {
    this.loadProperties();
    this.loadCategories();
    this.loadRFM();
    this.loadCustomInteractions()
    this.loadCampaigns();
    this.loadHosts();
    this.loadContactLists();
  },
  methods: {
    ...audienceMethods,
    ...propertyMethods,
    ...projectMethods,
    ...customerMethods,
    ...interactionMethods,
    ...campaignMethods,
    ...userMethods,
    ...helpers,

    addFixedProperties() {
      this.properties_products.unshift({
        type: 'product_price',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_price"),
        column_name: 'product_price'
      });
      this.properties_products.unshift({
        type: 'product_category',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
        column_name: 'product_category'
      });
      this.properties_products.unshift({
        type: 'any',
        name: this.$t('journeys.journey_workflow_components_wait_interaction_any'),
        column_name: 'any'
      });
    },

    loadProperties() {
      let loader = this.$loading.show();
      const params = {
        q: `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties) => {

        this.properties_products = properties.filter(p => p.entity == 'product');
        this.properties_crm = properties.filter(p => p.entity == 'customer');
        this.affinity_Types = this.properties_products.filter(p => p.type == 'list');
        this.affinity_Types.push({
          type: 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });

        this.addFixedProperties();


      }).catch(() => {

      }).finally(() => {
        loader.hide();
      })
    },
    loadCategories() {

      let loader = this.$loading.show();
      const params = {
        q: `project=${localStorage.getItem("current_project")}`
      }

      this.getItemsCategories(params).then((categories) => {
        this.categories = categories;
      }).catch(() => {

      }).finally(() => {
        loader.hide();
      })
    },
    loadRFM() {

      let loader = this.$loading.show();
      const params = {
        q: `project=${localStorage.getItem("current_project")}`
      }

      this.getRFMSegments(params).then((rfm_segments) => {
        this.rfm_segments = rfm_segments;
      }).catch(() => {

      }).finally(() => {
        loader.hide();
      })
    },

    loadCustomInteractions() {
      const params = {
        q: `where[project]=${localStorage.getItem('current_project')}`
      }
      this.getInteractions(params).then((res) => {
        if (res.data) {
          this.custom_interactions = res.data;
        }
      })
    },
    loadCampaigns() {
      const params = {
        q: `where[project]=${localStorage.getItem('current_project')}&limit=50&select=_id name`
      }
      this.getCampaigns(params).then((res) => {
        if (res.data) {
          this.campaigns = res.data;
        }
      })
    },

    loadHosts() {
      const params = {
        q: `where[role]=host&limit=50`,
      }
      this.getUsers(params).then((res) => {
        if (res.data) {
          this.hosts = res.data;
        }
      })
    },
    loadContactLists() {

      let loader = this.$loading.show();
      const params = {
        q: `where[project]=${localStorage.getItem('current_project')}&limit=100&where[status]=completed`
      }
      this.getContactLists(params).then((lists) => {
        if (lists && lists.data) {
          this.contactLists = lists.data;
        }
      }).catch(() => {
        this.$notify({ type: 'error', text: this.$t("contact_lists.get_contact_lists_error"), title: this.$t("contact_lists.title") });
      }).finally(() => {
        this.isLoading = false;
        loader.hide();
      });
    },
    onCreateConditionClicked() {
      this.showAudienceFields = true;
    },
    onAudienceFieldSelected(id) {
      this.query.children.push({
        field: id,
        operator: null,
        term: null,
        times: {},
        new: true
      });
      this.showAudienceFields = false;
    },
    applyAudienceExplorer() {
      if (this.query?.children?.length == 0) {
        this.totalCount = 0;
        this.successCount = 0;
        this.totalAOV = 0;
        this.totalRevenue = 0;
        this.percentage = '0%',
          this.purchases = 0;
        this.$set(this.series, 0, 0);
        this.$set(this.series, 1, 100 - 0);

        return;
      }
      let loader = this.$loading.show();
      const body = {
        project: localStorage.getItem('current_project'),
        rules: this.query,
        frequency: this.frequency.id
      }
      this.getAudienceExplore(body).then((res) => {
        if (res.transactionId) {
          //setTimeout(() => {
          this.waitForResults(res.transactionId, loader);
          //}, 3000);
          //this.waitForResults(res.transactionId);
        }
      }).catch(() => {
        loader.hide();
      }).finally(() => {

      })
    },
    waitForResults(transactionId, l) {
      let results = null;
      let self = this;

      // Función para llamar al servicio y manejar la lógica de espera
      function callService(loader) {

        // Llama al servicio usando el transactionId
        self.getAudienceExploreStatus(transactionId)
          .then(data => {
            results = data;

            if (!results || results.status == 'running') {
              if (results && self.lastResult?.totalCount == results?.totalCount) {
                self.withError = true;
                self.lastResult = null;
                loader.hide();
                return;
              }
              self.lastResult = results;
              setTimeout(() => { callService(loader) }, 5000);
            } else if (results.status == 'completed') {
              self.lastResult = null;
              self.withError = false;
              self.totalCount = results.totalCount,
                self.successCount = results.successCount;
              self.percentage = self.totalCount == 0 ? 0 : `${self.parsePercentage(self.successCount / self.totalCount * 100)}`;
              self.totalRevenue = results.totalRevenue;
              self.totalAOV = results.totalRevenue > 0 && results.totalOrders > 0 ? results.totalAOV = results.totalRevenue / results.totalOrders : 0;
              self.purchases = results.totalOrders || 0;
              if (self.totalCount == 0) {
                //self.$set(self.chartOptions.plotOptions.pie.donut, 'size', `${0}%`);
                self.$set(self.series, 0, 0);
                self.$set(self.series, 1, 100 - 0);
              }
              else {
                self.$set(self.series, 0, self.successCount / self.totalCount * 100);
                self.$set(self.series, 1, 100 - (self.successCount / self.totalCount * 100));
              }
              loader.hide()
            } else {
              loader.hide()
            }
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error('Error al llamar al servicio:', error);
            loader.hide()
          })
      }

      setTimeout(() => {
        callService(l);
      }, 2000)

    },
    onFrequencyChanged() {
      this.applyAudienceExplorer();
    },
    onSaveAudienceClicked() {
      this.audience = {};
      this.submitted = false;
      this.showSaveAudienceModal = true
    },
    onConfirmSaveAudienceClicked() {
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      let loader = this.$loading.show();

      const payload = {
        project: localStorage.getItem('current_project'),
        name: this.audience.name,
        description: this.audience.description,
        rules: this.query,
        frequency: this.frequency.id
      }

      this.createAudience(payload).then(() => {
        this.$notify({ type: 'success', text: this.$t("audiences.create_audience_success"), title: this.$t("audiences.title") });
        this.$router.push({
          path: "/audiences",
        }
        ).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        });
      }).catch(() => {
        this.$notify({ type: 'error', text: this.$t("audiences.create_audience_error"), title: this.$t("audiences.title") });
      }).finally(() => {
        loader.hide();
      })
    }

  },
  watch: {

  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{ $t('audience_explorer.title') }}</h5>
            <p class="text-muted mb-0">{{ $t('audience_explorer.subtitle') }}</p>
            <a class="small text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/segmentacion/explorador-de-audiencias" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
          </div>
          <div class="text-end w-25">
            <multiselect :options="frequencies" v-model="frequency" track-by="id" label="name" selectLabel=""
              deselectLabel="" class=" ms-2" :placeholder="$t('audiences.audience_frequency_placeholder')"
              @input="onFrequencyChanged" />
          </div>
        </div>
      </div>
      <div class="col-lg-12 mb-0">
        <div class="card" v-if="query.children.length == 0">
          <div class="card-body">
            <EmptyList :config="empty_config" v-if="query.children.length == 0"
              @onButtonClicked="onCreateConditionClicked" />
          </div>
        </div>
        <div class="card mb-2" v-if="query.children.length > 0">
          <div class="card-body pb-0">
            <div class="col-sm-12" v-if="query.children.length > 0">
              <label for="promotionName">{{ $t("audiences.audience_conditions") }}</label>
              <ExpressionBuilder :query="query" :conditionType="'audienceType'"
                :properties_products="properties_products" :affinity_Types="affinity_Types"
                :properties_crm="properties_crm" :categories="categories" :rfm_segments="rfm_segments"
                :custom_interactions="custom_interactions" :campaigns="campaigns" :hosts="hosts"
                :contactLists="contactLists" :is_explorer="true" @apply="applyAudienceExplorer"
                @saveAudience="onSaveAudienceClicked" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12" v-if="query?.children?.length > 0">
        <div class="card p-0">
          <div class="card-body py-2">
            <div class="row">
              <i class="fas fa-exclamation-triangle text-danger ms-auto text-end" v-if="withError"></i>
              <div class="col-sm-3 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  <div class="d-inline-flex align-items-center">
                    <apexchart class="apex-charts" dir="ltr" height="90" width="90" :series="series"
                      :options="chartOptions">
                    </apexchart>
                    <h1 class="mb-0 text-primary">{{ percentage }}%</h1>
                  </div>
                  <p class="small mb-0">{{ `${successCount} de ${totalCount} ${$t('audiences.customers')}` }}</p>
                </div>
              </div>
              <div class="col text-center m-auto" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  {{ $t('audiences.purchases') }}
                  <h3 class="text-primary mb-0" v-b-popover.hover.bottom="purchases || 0">{{
              parseCountReduced(purchases) || 0 }}</h3>
                </div>
              </div>
              <div class="col text-center m-auto" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  {{ $t('audiences.purchases_per_customer') }}
                  <h3 class="text-primary mb-0"
                    v-b-popover.hover.bottom="successCount == 0 ? 0 : parsePercentage(purchases / successCount)">{{
              successCount == 0 ? 0 : parsePercentage(purchases / successCount) || 0 }}</h3>
                </div>
              </div>
              <div class="col text-center m-auto" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  Revenue
                  <h3 class="text-primary mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(totalRevenue)}`">{{
              `$${parseMoneyReducedWithLocale(totalRevenue)}` }}</h3>
                </div>
              </div>
              <div class="col text-center m-auto" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  {{ $t('audiences.revenue_per_customer') }}
                  <h3 class="text-primary mb-0"
                    v-b-popover.hover.bottom="successCount == 0 ? `$0` : `$${parseMoneyWithLocale(totalRevenue / successCount)}`">
                    {{ successCount == 0 ? `$0` : `$${parseMoneyReducedWithLocale(totalRevenue / successCount)}` }}</h3>
                </div>
              </div>
              <div class="col text-center m-auto">
                <div>
                  AOV
                  <h3 class="text-primary mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(totalAOV)}`">{{
              `$${parseMoneyReducedWithLocale(totalAOV)}` }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal v-model="showAudienceFields" hide-footer :title="$t('audiences.audience_conditions')">
        <AudienceFields @onValueClicked="onAudienceFieldSelected" :custom_interactions="custom_interactions" />
      </b-modal>
      <b-modal v-model="showSaveAudienceModal" id="modal-center" centered :title="$t('audience_explorer.save_audience')"
        title-class="font-18" @ok.prevent="onConfirmSaveAudienceClicked" :okTitle="$t('common.confirm')"
        :cancelTitle="$t('common.cancel')">
        <div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label>{{ $t("audiences.audience_name") }}*</label>
                <input v-model="audience.name" name="name" type="text" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.audience.name.$error, }"
                  :placeholder="$t('audiences.audience_name_placeholder')" />
                <div v-if="submitted && !$v.audience.name.required" class="invalid-feedback">
                  {{ $t("audiences.audience_name_required") }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label>{{ $t("audiences.audience_description") }}*</label>
                <input v-model="audience.description" name="name" type="text" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.audience.description.$error, }"
                  :placeholder="$t('audiences.audience_description_placeholder')" />
                <div v-if="submitted && !$v.audience.description.required" class="invalid-feedback">
                  {{$t("audiences.audience_description_required")}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
